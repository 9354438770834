<style scoped>
.app-body {
	overflow-x: unset !important;
}

.card-body >>> table > tbody > tr > td {
	cursor: pointer;
}
</style>

<template>
	<errorContainer :error="erreur" :warning="warning">
		<b-row v-if="Dlvs">
			<b-col md="12">
				<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
					{{ FormMSG(215, 'User going to this accomodation') }}
				</h1>
			</b-col>
			<b-col cols="12" xl="12">
				<transition name="slide">
					<b-card no-body class="card-border-blue-light form">
						<div class="px-4 pt-3">
							<b-row>
								<b-col sm="12" class="d-flex justify-content-start">
									<button class="btn-transparent d-flex align-items-center" @click="$router.go(-1)">
										<component :is="getLucideIcon('ArrowLeft')" color="rgba(6, 38, 62, 0.65)" :size="22" class="icon" />
										<span class="title-back-bolder ml-1">{{ this.backTo() }}</span>
									</button>
								</b-col>
							</b-row>
							<div class="pt-3">
								<fieldset class="scheduler-border border-groove-blue-streak pt-0">
									<legend class="scheduler-border text-color-blue-streak">
										{{ this.caption1 }}<strong>{{ this.caption31 }}</strong>
									</legend>
									<b-row>
										<b-col sm="12" md="6" lg="6" xl="6">
											<b-form-group :label="FormMSG(50, 'Booking document')" label-for="accConfirmationFileBrowse">
												<b-form-file
													v-model="pdfFile"
													:browse-text="FormMSG(113, 'Browse')"
													name="test"
													id="accConfirmationFileBrowse"
													accept=".jpg,.png,.gif,.pdf,.xlsx,.xls,.doc,.docx,.txt"
													ref="pdfConfirmationFileId"
													@change="submitConfirmationFile"
													:placeholder="FormMSG(51, 'Choose a file or drop it here...')"
													:drop-placeholder="FormMSG(52, 'Drop file here...')"
												/>
											</b-form-group>
										</b-col>
										<b-col
											:sm="`${fileName && fileName.length > 0 ? 10 : 12}`"
											:md="`${fileName && fileName.length > 0 ? 4 : 6}`"
											:lg="`${fileName && fileName.length > 0 ? 4 : 6}`"
											:xl="`${fileName && fileName.length > 0 ? 4 : 6}`"
											class="d-flex justify-content-start align-items-end"
											style="margin-bottom: -3px"
										>
											<b-form-group label="" class="w-100">
												<b-form-input v-model="fileName" :disabled="true" type="text" id="docFileName"></b-form-input>
											</b-form-group>
										</b-col>
										<b-col
											v-if="fileName && fileName.length > 0"
											sm="2"
											md="2"
											lg="2"
											xl="2"
											class="d-flex justify-content-start align-items-center"
											style="margin-bottom: -16px"
										>
											<!-- <button
												:class="{
													'btn-transparent text-color-burning-tomato': $screen.width > 576,
													'btn btn-danger mr-2': $screen.width <= 576
												}"
												@click="removeFile"
											>
												<component :is="getLucideIcon('Trash2')" :size="22" :stroke-width="1.5" />
											</button> -->
											<button
												:class="{
													'btn-transparent text-color-rhapsody-in-blue': $screen.width > 576,
													'btn btn-primary': $screen.width <= 576
												}"
												@click="viewDocument(confirmation)"
											>
												<component :is="getLucideIcon('Eye')" :size="22" :stroke-width="1.5" />
											</button>
										</b-col>
									</b-row>
									<b-row>
										<b-col sm="12" md="6" lg="6" xl="6">
											<b-form-group :label="FormMSG(60, 'Accomodation from')" label-for="str_date">
												<b-form-datepicker id="str_date" :disabled="false" v-model="strDate" :locale="currentLang" />
											</b-form-group>
										</b-col>
										<b-col sm="12" md="6" lg="6" xl="6">
											<b-form-group :label="FormMSG(61, 'Until')" label-for="str_date">
												<b-form-datepicker id="end_date" :disabled="false" v-model="endDate" :locale="currentLang" />
											</b-form-group>
										</b-col>
									</b-row>
								</fieldset>
								<b-card-body class="p-0">
									<b-card no-body class="card-border-blue-light">
										<b-card-body>
											<fieldset class="scheduler-border border-groove-blue-streak pt-0">
												<legend class="scheduler-border text-color-blue-streak">{{ caption2 }}</legend>
												<b-row>
													<b-col sm="12" md="8" lg="8" xl="8">
														<b-row>
															<b-col sm="12" md="8" lg="8" xl="8">
																<b-form-group label-for="deliver-to-department">
																	<b-form-select
																		id="deliver-to-department"
																		v-model="selectedDepartment"
																		:options="mapDepartments"
																		@change="departmentChange"
																	>
																	</b-form-select>
																</b-form-group>
															</b-col>
															<b-col sm="12" md="4" lg="4" xl="4">
																<b-button
																	@click="addDepToSelection()"
																	variant="success"
																	:disabled="selectedDepartment == 0"
																	size="md"
																	block
																>
																	{{ this.FormMSG(71, 'Add departement to selection') }}
																</b-button>
															</b-col>
														</b-row>
														<b-row>
															<b-col sm="2" md="2" lg="2" xl="2" class="d-flex align-items-center">
																<div class="check-actions cursor-pointer" @click="switchCheckAll">
																	<div class="d-flex align-items-center" v-if="checkAll">
																		<span class="pr-2">{{ FormMSG(81, 'All') }}</span
																		><component
																			:is="getLucideIcon('CheckSquare')"
																			:size="18"
																			class="icon"
																			:stroke-width="2"
																		/>
																	</div>
																	<div class="d-flex align-items-center" v-else>
																		<span class="pr-2">{{ FormMSG(82, 'None') }}</span
																		><component :is="getLucideIcon('Square')" :size="18" class="icon" :stroke-width="2" />
																	</div>
																</div>
															</b-col>
															<b-col
																sm="10"
																md="10"
																lg="10"
																xl="10"
																class="d-flex align-items-center"
																:class="`${$screen.width < 992 ? 'py-3' : ''}`"
																v-if="$screen.width >= 992"
															>
																<b-input-group v-if="$screen.width >= 992">
																	<b-form-input v-model="filter" type="text" :placeholder="FormMSG(83, 'Type to Search')" />
																	<b-input-group-append class="cursor-pointer">
																		<b-input-group-text class="btn-search">
																			<component
																				:is="getLucideIcon('Search')"
																				color="#FFFFFF"
																				:size="16"
																				class="icon"
																				:stroke-width="2.5"
																				v-if="filter.length === 0"
																			/>
																			<component
																				:is="getLucideIcon('X')"
																				color="#FFFFFF"
																				:size="16"
																				class="icon"
																				:stroke-width="2.5"
																				@click="filter = ''"
																				v-else
																			/>
																		</b-input-group-text>
																	</b-input-group-append>
																</b-input-group>
															</b-col>
														</b-row>
													</b-col>
													<b-col sm="12" md="4" lg="4" xl="4" class="d-flex justify-content-center align-items-center">
														<b-button
															@click="publishAccomodation()"
															variant="outline-secondary"
															:disabled="this.somethingToPublish() == false"
															size="md"
															:style="{ width: `${$screen.width >= 992 ? '50%' : '100%'}` }"
														>
															{{ this.FormMSG(75, 'Publish selection') }}
														</b-button>
													</b-col>
												</b-row>
											</fieldset>
											<b-row>
												<b-col cols="12" xl="12" class="hide-on-desktop">
													<CardListBuilder :items="Dlvs" :fields="delifields" :omittedFields="['selected']" @row-clicked="rowClicked">
														<template slot="actions" slot-scope="data">
															<b-button
																variant="warning"
																v-if="
																	data.item.selected && data.item.status == 0 && data.item.startDate.substring(0, 4) != '2000'
																"
																size="sm"
																@click="unselectItem(data.item)"
															>
																<i class="icon-check"></i>
															</b-button>
															<b-button
																variant="warning"
																v-if="data.item.selected && data.item.status > 0"
																size="sm"
																@click="unselectItem(data.item)"
															>
																<i class="icon-check"></i>
															</b-button>
															<b-button
																variant="outline-primary"
																v-if="data.item.selected == false"
																size="sm"
																@click="selectItem(data.item)"
															>
																<i class="icon-check"></i>
															</b-button>
															<b-button
																variant="primary"
																size="sm"
																v-if="data.item.fileName && data.item.fileName.length > 0"
																@click="viewDocument(data.item.confirmation)"
															>
																<i class="icon-eye"></i>
															</b-button>
														</template>
													</CardListBuilder>
												</b-col>
												<b-col cols="12" xl="12" class="hide-on-tablet">
													<b-table
														:hover="hover"
														responsive="sm"
														ref="myTable"
														selectable
														sticky-header="700px"
														:selectedVariant="selectedColor"
														:select-mode="selectMode"
														:items="Dlvs"
														style="text-align: left"
														:fields="delifields"
														:current-page="currentPage"
														:filter="filter"
														:per-page="perPage"
														@row-clicked="rowClicked"
														:head-variant="hv"
														bordered
														small
													>
														<template v-slot:cell(selected)="data">
															<div class="cursor-pointer py-0">
																<b-form-checkbox
																	v-model="data.item.selected"
																	size="lg"
																	@change="selectAccomodation(data, $event)"
																	:disabled="data.item.status === 4"
																/>
															</div>
														</template>
														<template #cell(status)="data">
															<div class="wrap-status">
																<div :class="`status ${data.item.validatedClass}`" style="font-size: 0.7rem">
																	{{ data.value }}
																</div>
															</div>
														</template>
														<template v-slot:cell(view)="data">
															<b-button
																variant="primary"
																size="sm"
																v-if="data.item.fileName && data.item.fileName.length > 0"
																@click="viewDocument(data.item.confirmation)"
															>
																<i class="icon-eye"></i>
															</b-button>
														</template>
													</b-table>
												</b-col>
											</b-row>
										</b-card-body>
									</b-card>
								</b-card-body>
							</div>
						</div>
					</b-card>
				</transition>
			</b-col>
		</b-row>
		<b-modal
			header-class="header-class-modal-doc-package"
			:title="this.FormMSG(104, 'Success!')"
			class="modal-success"
			v-model="successModal"
			@ok="successModal = false"
			ok-variant="success"
			ok-only
		>
			{{ FormMSG(80, 'The new document has been saved.') }}
		</b-modal>
		<b-modal
			header-class="header-class-modal-doc-package"
			:title="this.FormMSG(104, 'Success!')"
			class="modal-success"
			v-model="successModalDelete"
			@ok="successModalDelete = false"
			ok-variant="success"
			ok-only
		>
			{{ FormMSG(103, 'Your document has been deleted.') }}
		</b-modal>
	</errorContainer>
</template>

<script>
import gql from 'graphql-tag';
import { store } from '@/store';
import languageMessages from '@/mixins/languageMessages';
import Loading from 'vue-loading-overlay';
import { isNil, objReducer } from '@/shared/utils';
import moment from 'moment';
import * as icons from 'lucide-vue';
// import axios from 'axios'
import { classDocumentStatus } from '@/shared/global-status';
import GlobalMixin from '@/mixins/global.mixin';

const axios = require('axios').create();

const query_deliveries = gql`
	query ($AccomodationId: ID!) {
		GetAccomodationDeliveryListChoice(AccomodationId: $AccomodationId) {
			user {
				id
				name
				firstName
				email
				departmentName
				functionName
				department
			}

			status
			selected
			startDate
			endDate
			fileName
			confirmation
		}
	}
`;

const mutation_publishAccomodation = gql`
	mutation ($AccomodationId: ID!) {
		PublishAccomodation(AccomodationId: $AccomodationId) {
			user {
				id
				name
				firstName
				email
				departmentName
				functionName
				department
			}
			status
			selected
			startDate
			endDate
			fileName
			confirmation
		}
	}
`;

const mutation_removeUserFromDelivery = gql`
	mutation ($AccomodationId: ID!, $UserId: ID!, $StrDate: String, $EndDate: String, $AccomodationDeliveryId: Int) {
		RemoveUserFromDeliveriesForAccomodation(
			AccomodationId: $AccomodationId
			UserId: $UserId
			StrDate: $StrDate
			EndDate: $EndDate
			AccomodationDeliveryId: $AccomodationDeliveryId
		) {
			user {
				id
				name
				firstName
				email
				departmentName
				functionName
				department
			}
			status
			selected
			startDate
			endDate
			fileName
			confirmation
		}
	}
`;

const mutation_DeliverAccomodationToUsers = gql`
	mutation ($AccomodationId: ID!, $UserIds: [Int]!, $StrDate: String, $EndDate: String, $FileName: String, $Confirmation: String) {
		DeliverAccomodationToUsers(
			AccomodationId: $AccomodationId
			UserIds: $UserIds
			StrDate: $StrDate
			EndDate: $EndDate
			FileName: $FileName
			Confirmation: $Confirmation
		) {
			user {
				id
				name
				firstName
				email
				departmentName
				functionName
				department
			}
			status
			selected
			startDate
			endDate
			fileName
			confirmation
		}
	}
`;

const query_departments = gql`
	query {
		GetDepartments {
			id
			value
			message
		}
	}
`;

export default {
	name: 'Distribution',
	mixins: [languageMessages, GlobalMixin],
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	components: {
		Loading
	},
	created() {
		this.strDate = new Date();
		this.endDate = new Date(this.strDate);
		this.endDate.setDate(this.endDate.getDate() + 1);
		if (this.timeZoneStr.length == 0) {
			this.firstTimeInitialisation();
		}
		this.reloadData();
	},
	data: () => {
		return {
			hv: 'dark',
			strDate: '2006-02-01T00:00:00+00:00',
			endDate: '2006-02-01T00:00:00+00:00',
			erreur: {},
			Dlvs: [],
			Deps: [],
			pdfFile: null,
			fileName: '',
			confirmation: '',
			curDep: 0,
			newAcc: {},
			curDeli: {},
			currentPage: 1,
			perPage: 0,
			warning: '',
			filter: '',
			isLoading: false,
			newCreated: 0,
			stopPropagation: '',
			selectMode: 'single',
			selectedColor: 'primary',
			successModal: false,
			successModalDelete: false,
			timeZoneStr: '',
			timeZoneHourDif: 0,
			selectedDepartment: 0,
			numItemSelected: 0,
			numItemPublished: 0,
			changeManyRecord: false,
			checkAll: true
		};
	},

	computed: {
		caption1() {
			return this.FormMSG(40, 'Select persons and periods for accomodation:') + ' ';
		},
		delifields() {
			return [
				{
					key: 'selected',
					label: this.FormMSG(1, 'Selected'),
					formatter: (v) => v,
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'fullName',
					label: this.FormMSG(111, 'Name'),
					sortable: true
				},
				{
					key: 'user.departmentName',
					label: this.FormMSG(2, 'Department'),

					sortable: true
				},
				{
					key: 'user.functionName',
					label: this.FormMSG(3, 'Function'),
					sortable: true
				},
				{
					key: 'startDate',
					label: this.FormMSG(4, 'From'),
					sortable: true,
					formatter: (value) => {
						if (value.substring(0, 4) == '2000') {
							return '';
						} else {
							return moment(value).format('DD/MM/YYYY');
						}
					}
				},
				{
					key: 'endDate',
					label: this.FormMSG(5, 'To'),
					sortable: true,
					formatter: (value) => {
						if (value.substring(0, 4) == '2000') {
							return '';
						} else {
							return moment(value).format('DD/MM/YYYY');
						}
					}
				},
				{
					key: 'status',
					label: this.FormMSG(6, 'Status'),
					formatter: (value) => {
						if (value == 0) {
							return this.FormMSG(20, 'Not published');
						}
						if (value == 1) {
							return this.FormMSG(21, 'Not received yet');
						}
						if (value == 2) {
							return this.FormMSG(22, 'Received (not read)');
						}
						if (value == 3) {
							return this.FormMSG(23, 'Read');
						}
						if (value == 4) {
							return this.FormMSG(24, 'Signed');
						}
					},
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'fileName',
					label: this.FormMSG(7, 'File name'),
					sortable: true
				},
				{
					key: 'view',
					label: this.FormMSG(8, 'View'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			];
		},
		noItemsToRemove() {
			var ret = true;
			for (var i = 0; i < this.Dlvs.length; i++) {
				if (this.Dlvs[i].startDate.length > 0) {
					ret = false;
					break;
				}
			}
			return ret;
		},

		caption2() {
			// compute number of selected elements
			var numItemFound = 0;
			this.numItemPublished = 0;
			for (var i = 0; i < this.Dlvs.length; i++) {
				if (this.Dlvs[i].selected) {
					numItemFound += 1;
				}
				if (this.Dlvs[i].status > 0) {
					this.numItemPublished += 1;
				}
			}
			this.numItemSelected = numItemFound;
			//console.log("print i:", numItemFound);
			var myCaption = this.FormMSG(65, 'Selected persons') + ' (' + numItemFound + ')';
			return myCaption;
		},
		caption31() {
			var newCaption = !isNil(this.newAcc) ? this.newAcc.name : '';
			return newCaption;
		},
		mapDepartments() {
			//console.log("in mapDepartments this.Deps", this.Deps);
			const departments = this.Deps.map((d) => {
				return {
					value: d.value,
					text: d.message
				};
			});
			return [
				{
					value: 0,
					text: this.FormMSG(66, 'Select a department')
				},
				...departments
			];
		}
	},
	methods: {
		caption3() {
			var newCaption = !isNil(this.newAcc) ? this.newAcc.name : '';
			return newCaption;
		},
		backTo() {
			var str = this.FormMSG(63, 'Back to');
			str += ' ' + this.caption3();
			return str;
		},
		removeDelivery(item) {
			console.log('in removeDelivery item:', item);
			this.removeUserFromDelivery(item);
		},
		displayRemoveIcon() {
			return true;
		},
		disableSaveButton() {
			console.log('in disable button');
			for (var i = 0; i < this.Dlvs.length; i++) {
				// status = 0 and date range defined
				if (this.Dlvs[i].status == 0 && this.Dlvs[i].startDate.substring(0, 4) != '2000') {
					return false;
				}
			}
			return true;
		},
		goBack() {
			this.$router.go(-1);
		},
		publishAccomodation() {
			console.log('id: ', this.newAcc.id);
			var AccomodationId = parseInt(this.newAcc.id, 10);
			this.$apollo
				.mutate({
					mutation: mutation_publishAccomodation,
					variables: {
						AccomodationId
					}
				})
				.then((result) => {
					this.Dlvs = result.data.PublishAccomodation;
					this.fillDlvs();
					//console.log("after publish dlvs:", this.Dlvs);
					//this.successModal = true;
				})
				.catch((error) => {
					console.log(error);
					//this.erreur = error
					var myerrorObject = {
						err: ''
					};
					myerrorObject.err = error.message;
					this.erreur = myerrorObject;
				});
		},
		addSelection() {
			var userIds = [];
			for (var i = 0; i < this.Dlvs.length; i++) {
				if (this.Dlvs[i].selected && this.Dlvs[i].startDate.substring(0, 4) == '2000') userIds.push(parseInt(this.Dlvs[i].user.id, 10));
			}
			if (userIds.length > 0) {
				this.selectUserForDelivery(userIds);
			}
		},
		selectItem(item) {
			item.selected = true;
			this.addSelection();
		},
		unselectItem(item) {
			this.removeUserFromDelivery(item);
			return;
		},
		departmentChange(departement) {
			this.curDep = departement;
		},
		addAllToDelivery() {
			this.selectUserForDelivery([-1]);
			return;
		},
		removeAllFromDelivery() {
			this.selectUserForDelivery([0]);
			return;
		},
		addDepToSelection() {
			//console.log("this.Dlvs.length",this.Dlvs.length);
			var oneFound = false;
			for (var i = 0; i < this.Dlvs.length; i++) {
				if (this.Dlvs[i].user.department == this.curDep) {
					this.Dlvs[i].selected = true;
					//console.log("this.Dlvs.user S:",this.Dlvs[i].user.name);
					oneFound = true;
				} else {
					//console.log("this.Dlvs.user S dep:",this.Dlvs[i].user.department);
					// console.log("this.Dlvs.user S nam:",this.Dlvs[i].user.name);
				}
			}
			if (oneFound) {
				this.addSelection();
			}
			this.reloadData();
			return;
		},
		loadDeps() {
			this.$apollo
				.query({
					query: query_departments,
					variables: {},
					// options: { fetchPolicy: 'network-only' },
					// options: { fetchPolicy: 'no-cache' },
					// force update from server
					fetchPolicy: 'network-only'
				})
				.then((result) => {
					this.Deps = result.data.GetDepartments;
					//console.log("deps:", this.Deps);
				})
				.catch((error) => {
					console.log(error);
					this.erreur = error;
				});
		},
		setTimeZoneStr() {
			// getting timezone
			if (this.timeZoneStr.length > 0) {
				return;
			}
			var d = new Date();
			var n = d.getTimezoneOffset();
			if (n < 0) {
				var hOffset = -n / 60;
				var sign = '+';
			} else {
				var hOffset = n / 60;
				var sign = '-';
			}
			var BASE = '00';
			this.timeZoneStr = hOffset ? sign + BASE.substr(0, 2 - Math.ceil(hOffset / 10)) + hOffset : sign + BASE;
			this.timeZoneStr = this.timeZoneStr + ':00';
			//console.log("timeZoneStr:",this.timeZoneStr);
			this.timeZoneHourDif = hOffset;
		},
		firstTimeInitialisation() {
			this.setTimeZoneStr();
			this.newAcc = store.state.curAccomodation;
			//console.log("this.newAcc in initialisation:", this.newAcc)
			this.loadDeps();
		},
		updateTimeToTZ(timeString) {
			this.setTimeZoneStr();
			if (timeString.length == 0) {
				return timeString;
			}
			// check if already converted
			//console.log("in updateTimeToTZ  RECEIVING:", timeString);
			//console.log("substring 9=",timeString.substring(9) );
			//console.log("in updateTimeToTZ timeZoneStr=", this.timeZoneStr);

			if (timeString.substring(9) != this.timeZoneStr) {
				// needs conversion
				//console.log("in updateTimeToTZ inval=", timeString)
				// extract time value
				var timStr = timeString.substring(1, 3);
				var hGmt = parseInt(timStr, 10);
				var h = (hGmt + this.timeZoneHourDif) % 24;
				if (h > 9) {
					var retval = 'T' + h + timeString.substring(3);
				} else {
					var retval = 'T0' + h + timeString.substring(3);
				}
				//    console.log("in updateTimeToTZ retval=",retval);
				retval = retval.substring(0, 9) + this.timeZoneStr;
				//console.log("in updateTimeToTZ retval new TZ=", retval);
				return retval;
			} else {
				// does not need conversion
				var retval = timeString;
				//console.log("in updateTimeToTZ retval kept existing conversion=", retval);
				return retval;
			}
		},
		receivedColor(item) {
			var retval = 'grey';
			//console.log("color:", item);
			if (item.status == 0 && item.startDate.substring(0, 4) != '2000') {
				retval = 'warning';
			} else if (item.status == 1) {
				retval = 'danger';
			} else if (item.status == 2) {
				retval = 'warning';
			} else if (item.status == 3) {
				retval = 'primary';
			} else if (item.status == 4) {
				retval = 'success';
			}
			return retval;
		},
		selectAll() {
			var found = false;
			for (var i = 0; i < this.Dlvs.length; i++) {
				if (this.Dlvs[i].selected == false) {
					this.Dlvs[i].selected = true;
					found = true;
				}
			}
			if (found) {
				this.addSelection();
			}
		},
		deselectAll() {
			for (var i = 0; i < this.Dlvs.length; i++) {
				this.Dlvs[i].selected = false;
			}
		},
		somethingToPublish() {
			for (var i = 0; i < this.Dlvs.length; i++) {
				if (this.Dlvs[i].selected && this.Dlvs[i].status == 0) {
					return true;
				}
			}

			return false;
		},
		fillDlvs() {
			for (let i = 0; i < this.Dlvs.length; i++) {
				//  this.Dlvs[i].selected = 0;
				if (
					(!this.Dlvs[i].user.name && !this.Dlvs[i].user.firstName) ||
					(this.Dlvs[i].user.name.length === 0 && this.Dlvs[i].user.firstName.length === 0)
				) {
					this.Dlvs[i].fullName = this.Dlvs[i].user.email;
				} else {
					this.Dlvs[i].fullName = this.Dlvs[i].user.name + ' ' + this.Dlvs[i].user.firstName;
				}

				this.Dlvs[i].validatedClass = classDocumentStatus(this.Dlvs[i].status);
			}
		},
		reloadData() {
			const { id } = this.$route.params;
			const AccomodationId = !isNil(this.newAcc) ? parseInt(this.newAcc.id, 10) : parseInt(id, 10);
			this.$apollo
				.query({
					query: query_deliveries,
					variables: {
						AccomodationId
					},
					// options: { fetchPolicy: 'network-only' },
					// options: { fetchPolicy: 'no-cache' },
					// force update from server
					fetchPolicy: 'network-only'
				})
				.then((result) => {
					this.Dlvs = result.data.GetAccomodationDeliveryListChoice;
					//console.log("reload data dlvs:",result);
					this.fillDlvs();
					//console.log("dlvs:", this.Dlvs);
				})
				.catch((error) => {
					console.log(error);
					this.erreur = error;
				});
		},
		selectUserForDelivery(userIds) {
			if (userIds[0] <= 0 || userIds.length > 0) {
				this.changeManyRecord = true;
			}
			var UserIds = userIds;
			//console.log("in selectUserForDelivery UserIds:", UserIds);
			// console.log('id: ', this.newAcc.id)
			var AccomodationId = parseInt(this.newAcc.id);
			//console.log("in selectUserForDelivery AccomodationId:", AccomodationId);
			var StrDate,
				EndDate = '';
			if (typeof this.strDate === 'string') {
				// format '2000-12-24
				StrDate = this.strDate + 'T00:00:00' + this.timeZoneStr;
			} else {
				StrDate = this.strDate.toISOString();
				StrDate = StrDate.split('T')[0] + 'T00:00:00' + this.timeZoneStr;
			}
			if (typeof this.endDate === 'string') {
				// format '2000-12-24
				EndDate = this.endDate + 'T00:00:00' + this.timeZoneStr;
			} else {
				EndDate = this.endDate.toISOString();
				EndDate = EndDate.split('T')[0] + 'T00:00:00' + this.timeZoneStr;
			}
			//console.log("StrDate:", StrDate);
			//console.log("EndDate:", EndDate);
			var Confirmation = this.confirmation;
			var FileName = this.fileName;

			this.$apollo
				.mutate({
					mutation: mutation_DeliverAccomodationToUsers,
					variables: {
						AccomodationId,
						UserIds,
						StrDate,
						EndDate,
						FileName,
						Confirmation
					}
				})
				.then((result) => {
					//console.log("result from selectUserForDelivery:", result);
					if (this.changeManyRecord) {
						this.Dlvs = result.data.DeliverAccomodationToUsers;
						this.fillDlvs();
						this.changeManyRecord = false;
					}
					//this.successModalDelete = true;
				})
				.catch((error) => {
					console.log(error);
					this.changeManyRecord = false;
					this.erreur = error;
				});
		},
		removeUserFromDelivery(item) {
			var UserId = parseInt(item.user.id, 10);
			// console.log("1in removeUserFromDelivery UserId:", UserId);
			var AccomodationId = parseInt(this.newAcc.id, 10);
			// console.log("2in removeUserFromDelivery AccomodationId:", AccomodationId);
			var StrDate,
				EndDate = '';

			// format 2020-08-06T00:00:00Z
			StrDate = item.startDate.substring(0, 10) + 'T00:00:00'; // + this.timeZoneStr;
			//console.log("in STRING removeUserFromDelivery StrDate:", StrDate);
			EndDate = item.endDate.substring(0, 10) + 'T00:00:00'; // + this.timeZoneStr;
			//console.log("in STRING removeUserFromDelivery EndDate:", EndDate);

			//console.log("in removeUserFromDelivery StrDate:", StrDate);
			//console.log("in removeUserFromDelivery item.startDate:", item.startDate);
			//console.log("in removeUserFromDelivery EndDate:", EndDate);

			this.$apollo
				.mutate({
					mutation: mutation_removeUserFromDelivery,
					variables: {
						AccomodationId,
						UserId,
						StrDate,
						EndDate
					}
				})
				.then((result) => {
					this.Dlvs = result.data.RemoveUserFromDeliveriesForAccomodation;
					this.fillDlvs();
					//this.successModalDelete = true;
				})
				.catch((error) => {
					console.log(error);
					this.erreur = error;
				});
		},
		getRowCount(items) {
			//console.log(items)
			return items.length;
		},
		rowClicked(item) {
			// copy item content into curDoc structure.
			//                this.$refs.myTable.clearSelected();
			//      console.log("item in rowClicked  this.$refs.myTable:",  this.$refs.myTable);
			//console.log("item in rowClicked:", item);
			this.curDeli = item;
		},
		clickRow(idx) {
			//this.currentPage = parseInt(parseInt(idx, 10) / parseInt(this.perPage, 10), 10) + 1;
			this.$nextTick(function () {
				// Code that will run only after the
				// entire view has been re-rendered
				// found in https://jsfiddle.net/sem409uc/
				if (!isNil(this.$refs.myTable)) {
					let myTable = this.$refs.myTable.$el,
						tableBody = myTable.getElementsByTagName('tbody')[0],
						tableRows = tableBody.getElementsByTagName('tr');
					tableRows[idx].click();
				}
			});
		},
		removeDocument(xid) {
			return;
		},
		submitConfirmationFile(ev) {
			this.isLoading = true;
			if (ev.type == 'drop') {
				var docFil = event.dataTransfer.files[0];
			} else if (ev.type == 'change') {
				var docFil = ev.target.files[0];
			}
			//console.log("submitConfirmationFile submit file:", docFil);
			let formData = new FormData();
			formData.append('uploadimage', docFil);
			formData.append('fileName', docFil.name);
			if (this.confirmation.length > 0) {
				// new document selected, remove the old file from the server
				this.removeDocument(this.confirmation);
			}
			this.fileName = docFil.name;
			formData.append('fileType', docFil.type);
			//console.log("in submitConfirmationFile, fileName:", this.fileName);
			var urlStr = process.env.VUE_APP_GQL + '/upload';
			//console.log("in submitConfirmationFile, urlStr:", urlStr);
			//console.log("in submitConfirmationFile, formData:", formData);
			var axiosTmp = require('axios').create();

			axiosTmp
				.post(urlStr, formData, {
					headers: {
						Authorization: `Bearer ${store.state.token}`,
						'Content-Type': 'multipart/form-data'
					}
				})
				.then((result) => {
					//console.log("submitConfirmationFile success result:", result);
					this.confirmation = result.data;
					this.$refs['pdfConfirmationFileId'].reset();
				})
				.catch(function (error) {
					alert('ERROR: the file was not updloaded to the server due to unknown error (reload the page and retry..)');
					//console.log(" submitConfirmationFile FAILURE!!: ",error);
				})
				.finally(() => (this.isLoading = false));
		},
		removePdfConfirmationFile() {
			if (this.confirmation.length > 0) {
				console.log('in removePdfConfirmationFile remove old doc:', this.confirmation);
				// new document selected, remove the old file from the server
				this.removeDocument(this.confirmation);
			}
			this.fileName = '';
		},
		viewDocument(confirmation) {
			if (confirmation.length > 0) {
				var urlStr = process.env.VUE_APP_GQL + '/images/' + confirmation;
			}
			window.open(urlStr); // in a new window

			return;
		},
		getLucideIcon(name) {
			return icons[name];
		},
		removeFile() {
			this.$bvModal
				.msgBoxConfirm(this.FormMSG(76, 'Are you sure to remove this file ?'), {
					title: this.FormMSG(77, 'Confirm'),
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: this.FormMSG(78, 'YES'),
					cancelTitle: this.FormMSG(79, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (v) => {
					if (!v) return;

					if (this.newAcc.xid && this.newAcc.xid.length > 0) {
						//console.log("in submitDocFile remove old doc:", this.newDoc.xid)
						// new document selected, remove the old file from the server
						this.removeDocument(this.newAcc.xid);
						if (this.newDoc.xidGenerated && this.newDoc.xidGenerated.length > 0) {
							// remove also the previous xidGenerated if any
							this.removeDocument(this.newAcc.xidGenerated);
						}
					}
					this.newAcc.fileName = '';
					this.newAcc.xid = '';
					this.newAcc.xidGenerated = '';
				})
				.catch((err) => console.log(err));
		},
		removeDocument(xid) {
			// console.log('removing document :', xid)
			let retStr = '';
			let formData = new FormData(); // instantiate it
			formData.append('fileName', xid);
			const config = {
				headers: {
					Authorization: `Bearer ${store.state.token}`,
					'content-type': 'multipart/form-data'
				}
			};
			let urlStr = process.env.VUE_APP_GQL + '/removedocument';
			axios
				.post(urlStr, formData, config)
				.then((response) => {
					retStr = response.data;
					//console.log("document removed:", retStr);
					this.isLoading = false;
				})
				.catch((error) => {
					console.log(error);
				});
			return retStr;
		},
		selectAccomodation(data, payload) {
			if (payload) {
				this.addSelection();
			} else {
				this.removeUserFromDelivery(data.item);
			}
		},
		switchCheckAll() {
			if (this.checkAll) {
				let found = false;
				for (let i = 0; i < this.Dlvs.length; i++) {
					if (this.Dlvs[i].selected == false) {
						this.Dlvs[i].selected = true;
						found = true;
					}
				}

				if (found) {
					this.addSelection();
				}
			} else {
				for (let i = 0; i < this.Dlvs.length; i++) {
					this.Dlvs[i].selected = false;
				}
			}
			this.checkAll = !this.checkAll;
		}
	}
};
</script>
